$component: 'card';

$card-cover-width: 160px;
$card-bg: $gray-100;
$card-title-transition: color 0.15s ease-in-out;

.#{$component} {
  display: block;

  @include media-breakpoint-up(sm) {
    display: flex;
    align-items: stretch;
  }

  &:hover {
    .#{$component}__title {
      color: $primary;
    }
  }

  &__cover {
    @extend .ratio, .ratio-16x9;

    @include media-breakpoint-up(sm) {
      width: $card-cover-width;
      min-width: $card-cover-width;
      margin-right: ($spacer * 0.5);
    }

    @include media-breakpoint-up(md) {
      width: ($card-cover-width * 1.5);
      min-width: ($card-cover-width * 1.5);
    }

    @include media-breakpoint-up(lg) {
      width: ($card-cover-width * 2);
      min-width: ($card-cover-width * 2);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__main {
    padding: $spacer;
    padding-top: ($spacer * 0.75);

    background-color: $card-bg;

    @include media-breakpoint-up(sm) {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      min-height: 100%;
      padding: $spacer ($spacer * 1.25);
    }
  }

  &__meta {
    color: $text-muted;
  }

  &__title {
    margin-bottom: $spacer;
    font-weight: $font-weight-medium;
    color: $dark;

    transition: $card-title-transition;

    @include media-breakpoint-up(sm) {
      @include text-crop(3);

      margin-bottom: auto;
    }
  }

  &-caption {
    margin-bottom: $spacer;

    font-size: $h4-font-size;
    font-weight: $font-weight-medium;

    @include media-breakpoint-up(sm) {
      margin-bottom: ($spacer * 1.5);
      font-size: ($font-size-base * 1.75);
    }
  }
}
