$primary-500: #ff4c4c;
$primary-500-a60: rgba(#ff4d4d, 0.6);
$primary-500-a40: rgba(#ff4d4d, 0.4);
$primary-500-a20: rgba(#ff4c4c, 0.2);
$primary-500-a10: rgba(#ff4c4c, 0.1);

$gray-900: #141414;
$gray-900-a80: rgba(#141414, 0.8);
$gray-900-a60: rgba(#141414, 0.6);
$gray-900-a40: rgba(#141414, 0.4);
$gray-900-a20: rgba(#141414, 0.2);
$gray-900-a10: rgba(#141414, 0.1);
$gray-900-a05: rgba(#141414, 0.05);
$gray-800: #3d3d3d;
$gray-700: #666666;
$gray-600: #8e8e8e;
$gray-500: #b7b7b7;
$gray-400: #cccccc;
$gray-300: #e0e0e0;
$gray-200: #eaeaea;
$gray-100: #f4f4f4;

$white: #ffffff;
$white-a80: rgba(#ffffff, 0.8);
$white-a60: rgba(#ffffff, 0.6);
$white-a40: rgba(#ffffff, 0.4);
$white-a20: rgba(#ffffff, 0.2);
$white-a10: rgba(#ffffff, 0.1);
$white-a05: rgba(#ffffff, 0.0);
