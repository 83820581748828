$component: "footer";

$footer-width-md: 668px;
$footer-width-xs: 560px;

$footer-left-spacer-xl: $line-left-spacer-xl;
$footer-left-spacer-lg: $line-left-spacer-lg;
$footer-left-spacer-md: $line-left-spacer-md;
$footer-left-spacer-sm: $line-left-spacer-sm;
$footer-left-spacer-xs: $line-left-spacer-xs;

$footer-pl-sm: ($spacer * 3);
$footer-pl-xs: ($spacer * 2);

.#{$component} {
  @extend .position-relative, .line;
  padding: ($spacer * 5) 0 ($spacer * 2.5) 0;

  font-size: $font-size-xs;
  line-height: $line-height-md;
  color: $text-muted;

  @include media-breakpoint-up(sm) {
    padding: ($spacer * 8) 0 ($spacer * 2.5) 0;
  }

  @include media-breakpoint-up(md) {
    padding: ($spacer * 10) 0 ($spacer * 2.5) 0;
  }

  &__container {
    padding-left: $footer-pl-xs;
    margin-left: $footer-left-spacer-xs;

    @include media-breakpoint-up(sm) {
      padding-left: $footer-pl-sm;
      margin-left: $footer-left-spacer-sm;
    }

    @include media-breakpoint-up(md) {
      margin-left: $footer-left-spacer-md;
    }

    @include media-breakpoint-up(lg) {
      margin-left: $footer-left-spacer-lg;
    }

    @include media-breakpoint-up(xl) {
      margin-left: $footer-left-spacer-xl;
    }
  }

  &__logo {
    @extend .d-inline-block, .mt-3;
    color: inherit;

    &:hover {
      color: $dark;
    }
  }

  p {
    max-width: $footer-width-xs;
    margin-bottom: ($spacer * 0.5);

    font-size: inherit;

    @include media-breakpoint-up(md) {
      max-width: $footer-width-md;
    }
  }
}
