$component: 'btn';

// TODO: refact

.#{$component} {
  @extend .position-relative;

  padding-top: 6px;
  padding-bottom: 8px;

  font-size: $font-size-sm;
  font-weight: $font-weight-medium;

  @include media-breakpoint-up(sm) {
    font-size: $font-size-md;
  }

  .icon-ref {
    @extend .position-absolute, .top-50, .translate-middle-y;
    right: $input-btn-padding-x;
  }

  &-primary {
    padding-right: ($spacer * 2.25);

    color: $primary;
    background-color: $primary-500-a10;
    border-color: transparent;

    text-align: left;

    &:hover,
    &:focus {
      color: $primary;
      background-color: $primary-500-a20;
      border-color: transparent;
      box-shadow: none;

      svg {
        opacity: 1;
      }
    }

    &:focus-visible {
      box-shadow: 0 0 0 transparent, 0 0 0 2px $primary-500-a10;
    }

    svg {
      transition: opacity 150ms ease-in-out;;
      opacity: 0.2;
    }
  }

  &-outline-primary {
    color: $primary;
    border-color: $primary-500-a20;

    &:hover,
    &:focus {
      color: $primary;
      background-color: transparent;
      border-color: $primary-500-a60;
      box-shadow: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0 transparent, 0 0 0 2px $primary-500-a10;
    }
  }

  &-light {
    color: $light;
    background-color: $white-a10;
    border-color: transparent;

    &:hover,
    &:focus {
      color: $light;
      background-color: $white-a20;
      border-color: transparent;
      box-shadow: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0 transparent, 0 0 0 2px $white-a40;
    }
  }

  svg {
    position: relative;
    bottom: 1px;

    @include media-breakpoint-up(sm) {
      bottom: 2px;
    }
  }
}
