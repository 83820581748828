$component: 'header';

$header-logo-translate-y: 13%;

$header-photo-size-sm: 220px;
$header-photo-size-xs: 160px;

$header-photo-lg-size: 440px;

$header-photo-sm-size-xxl: 300px;
$header-photo-sm-size-xl: 260px;

$header-photo-1-top-lg: 40px;
$header-photo-1-top-xs: -56px;
$header-photo-1-right-lg: 20px;
$header-photo-1-right-xs: 20px;

$header-photo-2-top-xxl: -64px;
$header-photo-2-top-xl: -54px;
$header-photo-2-top-xs: -48px;
$header-photo-2-right-xxl: -64;
$header-photo-2-right-xl: 0;
$header-photo-2-right-xs: -24px;

$header-photo-3-top-xxl: -15px;
$header-photo-3-top-xl: -15px;
$header-photo-3-top-xs: -15px;
$header-photo-3-left-xxl: -84px;
$header-photo-3-left-xl: -68px;
$header-photo-3-left-xs: 10px;

.#{$component} {
  @extend .position-relative, .overflow-hidden;

  @include media-breakpoint-up(lg) {
    padding-bottom: ($spacer * 5);
  }

  &__bg {
    @extend .position-absolute, .top-0, .end-0, .start-0, .bottom-0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover
    }
  }

  &__container {
    @extend .wrapper;
  }

  &__logo {
    transform: translateY(-$header-logo-translate-y);

    img {
      max-width: 100%;
    }
  }

  &__description {
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $white;

    @include media-breakpoint-up(sm) {
      font-size: $h3-font-size;
      line-height: $line-height-sm;
    }

    @include media-breakpoint-up(md) {
      font-size: $h2-font-size;
      line-height: $line-height-sm;
    }
  }

  &__photo {
    @extend .rounded-circle, .overflow-hidden;
    align-self: top;

    width: $header-photo-size-xs;
    min-width: $header-photo-size-xs;
    height: $header-photo-size-xs;
    margin: ($spacer * 0.5) ($spacer * 2);

    @include media-breakpoint-up(sm) {
      width: $header-photo-size-sm;
      min-width: $header-photo-size-sm;
      height: $header-photo-size-sm;
      margin: $spacer ($spacer * 2);
    }

    @include media-breakpoint-up(lg) {
      margin: 0;
    }

    &_lg {
      @include media-breakpoint-up(lg) {
        width: $header-photo-lg-size;
        min-width: $header-photo-lg-size;
        height: $header-photo-lg-size;
      }
    }

    &_sm {
      @include media-breakpoint-up(xl) {
        width: $header-photo-sm-size-xl;
        min-width: $header-photo-sm-size-xl;
        height: $header-photo-sm-size-xl;
      }

      @include media-breakpoint-up(xxl) {
        width: $header-photo-sm-size-xxl;
        min-width: $header-photo-sm-size-xxl;
        height: $header-photo-sm-size-xxl;
      }
    }

    &_1, &_2, &_3 {
      @extend .position-absolute;
    }

    &_1 {
      top: $header-photo-1-top-xs;
      right: $header-photo-1-right-xs;

      @include media-breakpoint-up(lg) {
        top: $header-photo-1-top-lg;
        right: $header-photo-1-right-lg;
      }
    }

    &_2 {
      top: $header-photo-2-top-xs;
      right: $header-photo-2-right-xs;

      @include media-breakpoint-up(xl) {
        top: $header-photo-2-top-xl;
        right: $header-photo-2-right-xl;
      }

      @include media-breakpoint-up(xxl) {
        top: $header-photo-2-top-xxl;
        right: $header-photo-2-right-xxl;
      }
    }

    &_3 {
      top: $header-photo-3-top-xs;
      left: $header-photo-3-left-xs;

      @include media-breakpoint-up(xl) {
        left: $header-photo-3-left-xl;
      }

      @include media-breakpoint-up(xxl) {
        left: $header-photo-3-left-xxl;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__summaries {
    padding: ($spacer * 0.5) $spacer 0 $spacer;
    border-top: 1px solid $white-a40;

    @include media-breakpoint-up(md) {
      padding: ($spacer * 0.5) ($spacer * 1.5) 0 ($spacer * 1.5);
    }

    @include media-breakpoint-up(lg) {
      padding: 0;
      margin-top: ($spacer * 5);
    }

    &_desktop {
      @extend .d-none, .d-lg-block;
    }

    &_mobile {
      @extend .d-flex, .d-lg-none;

      padding-bottom: ($spacer * 2.5);
      margin-left: -$spacer;
      margin-right: -$spacer;

      overflow-x: scroll;

      @include media-breakpoint-up(sm) {
        padding-bottom: ($spacer * 4);
      }

      @include media-breakpoint-up(md) {
        margin-left: -($spacer * 1.5);
        margin-right: -($spacer * 1.5);
      }
    }

    & + & {
      margin-top: ($spacer * 2);

      @include media-breakpoint-up(md) {
        margin-top: ($spacer * 4);
      }
    }
  }
}
