$component: 'embed';

.#{$component} {
  @extend .narrow;
  margin-left: -($spacer * 3);

  @include media-breakpoint-up(sm) {
    margin-left: 0;
  }

  iframe {
    width: 100%;
  }
}
