$component: 'navbar';

$navbar-bg: #f5f5f5;
$navbar-item-color: $primary;
$navbar-item-color-hover: $primary-500-a60;
$navbar-item-border: 1px solid $primary-500-a20;

.#{$component} {
  position: sticky;
  top: 0;
  z-index: 2;

  padding: 0 ($spacer * 0.5);

  background-color: $navbar-bg;

  @include media-breakpoint-up(md) {
    padding: $spacer 0;
  }

  &__container {
    @extend .wrapper, .align-items-center;
    display: flex;

    @include media-breakpoint-down(md) {
      padding: 0;
    }

    // &_desktop {
    //   display: flex;
      // display: none;

      // @include media-breakpoint-up(md) {
      //   display: flex;
      // }
    // }

    // &_mobile {
    //   padding: 0;
    //   display: flex;

    //   @include media-breakpoint-up(md) {
    //     display: none;
    //   }
    // }
  }

  &__item {
    @extend .d-flex, .align-items-center, .h-100;
    padding: $spacer ($spacer * 0.5);

    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
    color: $navbar-item-color;

    // border-right: $navbar-item-border;

    &:last-child {
      border-right: 0;
    }

    @include media-breakpoint-up(sm) {
      font-size: $font-size-md;
    }

    @include media-breakpoint-up(md) {
      border-right: 0;
      padding: 0;
    }

    &:hover {
      color: $navbar-item-color-hover;
    }

    & + & {
      @include media-breakpoint-up(md) {
        margin-left: ($spacer * 2.5);
      }
    }

    &.active {
      color: $dark;
    }
  }
}
