$component: 'projects';

$projects-bg: $gray-100;

.#{$component} {
  position: relative;

  padding: $spacer;
  margin-left: -($spacer * 3);

  background-color: $projects-bg;

  @include media-breakpoint-up(sm) {
    padding: ($spacer * 1.5);
    margin-left: 0;
  }

  &__item {
    @extend .narrow;

    & + & {
      margin-top: ($spacer * 1.5);

      @include media-breakpoint-up(sm) {
        margin-top: ($spacer * 2);
      }

      @include media-breakpoint-up(md) {
        margin-top: ($spacer * 2.5);
      }
    }
  }

  &__description {
    &:not(:first-child) {
      margin-top: ($spacer * 0.5);

      @include media-breakpoint-up(sm) {
        margin-top: $spacer;
      }
    }

    p {
      margin-bottom: 0;

      &:not(:first-child) {
        margin-top: $spacer;
      }
    }

    img {
      @extend .d-inline-block, .w-100;
      margin-top: $spacer;
    }
  }
}
