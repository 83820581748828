$component: 'gallery';

.#{$component} {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  .timeline__item & {
    margin-left: -($spacer * 3);

    @include media-breakpoint-up(sm) {
      margin-left: 0;
    }
  }

  &__item {
    @extend .ratio, .ratio-16x9;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    & + & {
      margin-top: ($spacer * 0.5);

      @include media-breakpoint-up(md) {
        margin-top: 0;
        margin-left: ($spacer * 0.5);
      }
    }
  }
}
