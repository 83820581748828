$component: 'summary';

$summary-color: $white;

$summary-min-width-lg: auto;
$summary-min-width-sm: 340px;
$summary-min-width-xs: 260px;

.#{$component} {
  min-width: $summary-min-width-xs;
  color: $summary-color;

  @include media-breakpoint-up(sm) {
    min-width: $summary-min-width-sm;
  }

  @include media-breakpoint-up(lg) {
    min-width: $summary-min-width-lg;
  }

  & + & {
    margin-left: ($spacer * 1.5);

    @include media-breakpoint-up(md) {
      margin-left: ($spacer * 3.5);
    }
  }

  &__title {
    margin: ($spacer * 0.25) 0 ($spacer * 0.5) 0;

    font-size: $h3-font-size;
    line-height: 1;
    font-weight: $font-weight-bolder;

    @include media-breakpoint-up(sm) {
      margin: $spacer 0 ($spacer * 0.25) 0;
      font-size: $h2-font-size;
    }
  }

  // &__description {}

  &__link {
    @extend .btn, .btn-light, .mt-3;
  }
}
