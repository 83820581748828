@font-face {
  font-family: 'Golos';
  src: url('/fonts/golos-regular.woff2') format('woff2'),
  url('/fonts/golos-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Golos';
  src: url('/fonts/golos-medium.woff2') format('woff2'),
  url('/fonts/golos-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Golos';
  src: url('/fonts/golos-black.woff2') format('woff2'),
  url('/fonts/golos-black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
