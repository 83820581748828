$component: 'line';

$line-border-color: $primary-500-a40;

$line-left-spacer-xl: 207px;
$line-left-spacer-lg: 127px;
$line-left-spacer-md: 58px;
$line-left-spacer-sm: 37px;
$line-left-spacer-xs: 15px;

.#{$component} {
  &:before {
    content: '';
    position: absolute;
    left: $line-left-spacer-xs;
    top: 0;
    bottom: 0;

    transform: translate(-1px);

    display: inline-block;
    width: 1px;
    background-color: $line-border-color;

    @include media-breakpoint-up(sm) {
      left: $line-left-spacer-sm;
    }

    @include media-breakpoint-up(md) {
      left: $line-left-spacer-md;
    }

    @include media-breakpoint-up(lg) {
      left: $line-left-spacer-lg;
    }

    @include media-breakpoint-up(xl) {
      left: $line-left-spacer-xl;
    }
  }
}
