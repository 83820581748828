$component: 'blockquote';

$blockquote-bg: $gray-100;
$blockquote-avatar-size-xl: 160px;
$blockquote-avatar-size-lg: 120px;
$blockquote-avatar-size-sm: 64px;
$blockquote-avatar-size-xs: 56px;

.#{$component} {
  position: relative;
  padding: $spacer;
  margin: 0;

  font-size: $font-size-sm;

  background-color: $blockquote-bg;

  @include media-breakpoint-up(sm) {
    padding: ($spacer * 1.5);
    font-size: $font-size-md;
  }

  @include media-breakpoint-up(lg) {
    min-height: calc(#{$blockquote-avatar-size-lg} + #{$spacer * 3});
    padding-left: $blockquote-avatar-size-lg + ($spacer * 3.5 / 1rem * 16px);
  }

  @include media-breakpoint-up(xl) {
    min-height: calc(#{$blockquote-avatar-size-xl} + #{$spacer * 3});
    padding-left: $blockquote-avatar-size-xl + ($spacer * 6 / 1rem * 16px);
  }

  .gallery + & {
    @extend .mt-3, .mt-sm-4;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: $spacer;

    @include media-breakpoint-up(lg) {
      display: block;
      margin-bottom: 0;
    }
  }

  &__avatar {
    width: $blockquote-avatar-size-xs;
    min-width: $blockquote-avatar-size-xs;
    height: $blockquote-avatar-size-xs;
    margin-right: $spacer;
    border-radius: 50%;

    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include media-breakpoint-up(sm) {
      width: $blockquote-avatar-size-sm;
      min-width: $blockquote-avatar-size-sm;
      height: $blockquote-avatar-size-sm;

      margin-right: ($spacer * 1.25);
    }

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: ($spacer * 1.5);
      left: ($spacer * 1.5);

      width: $blockquote-avatar-size-lg;
      min-width: $blockquote-avatar-size-lg;
      height: $blockquote-avatar-size-lg;

      margin-right: 0;
    }

    @include media-breakpoint-up(xl) {
      width: $blockquote-avatar-size-xl;
      min-width: $blockquote-avatar-size-xl;
      height: $blockquote-avatar-size-xl;
    }
  }

  &__title {
    margin-bottom: 0;
    font-size: inherit;

    @include media-breakpoint-up(lg) {
      margin-bottom: $spacer;
    }
  }

  &__body {
    @extend .narrow;
  }
}
