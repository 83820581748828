//https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss

$primary:                   $primary-500;
// $secondary:                 $flamingo-500-a20;
// $success:                   $success-300;
// $info:                      $success-500;
// $danger:                    $error-500;
$light:                     $white;
$dark:                      $gray-900;

$theme-colors: (
  'primary':                $primary,
  // 'secondary':              $secondary,
  // 'success':                $success,
  // 'info':                   $info,
  // 'danger':                 $danger,
  'light':                  $light,
  'dark':                   $dark,
);

// Options
//
$enable-shadows:            true;

// Body
//
$body-bg:                   $light;
$body-color:                $dark;

// Links
//
// $link-color:             $primary !default;
$link-decoration:           none;

// Grid breakpoints
//
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1400px
);

// Components
//
$border-color:              $gray-900-a10;

// $border-radius-lg:          0.5rem;

// $component-active-color:    $white;
// $component-active-bg:       $primary;

// Typography
//
$font-family-sans-serif: (
  Golos,
  system-ui,
  -apple-system,
  'Segoe UI',
  Roboto,
  'Helvetica Neue',
  Arial,
  'Noto Sans',
  'Liberation Sans',
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  'Noto Color Emoji'
);

// TODO:
$font-size-base:            1rem; // 16px;
$font-size-md:              ($font-size-base * 1.125); // 18px
$font-size-sm:              ($font-size-base * 0.875); // 14px
$font-size-xs:              ($font-size-base * 0.75); // 12px

$line-height-base:          1.3;
$line-height-sm:            1.2;
$line-height-md:            1.4;
$line-height-lg:            1.5;

$font-weight-normal:        400;
$font-weight-medium:        500;
$font-weight-bolder:        900;

$h1-font-size:              ($font-size-base * 4); // 64px
$h2-font-size:              ($font-size-base * 2.5); // 40px
$h3-font-size:              ($font-size-base * 2); // 32px
$h4-font-size:              $font-size-md; // 18px
$h5-font-size:              $font-size-sm; // 14px
$h6-font-size:              $font-size-sm; // 14px

$font-sizes: (
  1:                        $h1-font-size, // 64px
  2:                        $h2-font-size, // 40px
  3:                        $h3-font-size, // 32px
  4:                        $h4-font-size, // 18px
  5:                        $h5-font-size, // 14px
  6:                        $h6-font-size  // 14px
);

$display-font-weight:       inherit;
$display-line-height:       $line-height-base;

$small-font-size:           $font-size-sm; // 12px

$text-muted:                $gray-900-a40;

$hr-opacity:                0.05;

// Buttons + Forms
//
$input-btn-padding-y:           0.469rem;
$input-btn-padding-x:           0.75rem;
$input-btn-font-size:           ($font-size-base * 1.125);
$input-btn-line-height:         1.143;

$input-btn-focus-width:         2px;
$input-btn-focus-color-opacity: 0.2;
$input-btn-focus-color:         rgba($white, $input-btn-focus-color-opacity);
$input-btn-focus-blur:          0;
$input-btn-focus-box-shadow:    0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm:        0.375rem;
$input-btn-padding-x-sm:        0.75rem;
$input-btn-font-size-sm:        $input-btn-font-size;

$input-btn-padding-y-lg:        0.875rem;
$input-btn-padding-x-lg:        1.5rem;
$input-btn-font-size-lg:        $font-size-base;

// Buttons
//
$btn-font-weight:               $font-weight-normal;
$btn-disabled-opacity:          1;
$btn-box-shadow:                0 0 0 transparent;
$btn-active-box-shadow:         0;

$btn-border-radius:             18px;
// $btn-border-radius-sm:        $border-radius-sm !default;
// $btn-border-radius-lg:        $border-radius-lg !default;

// $btn-border-width:           0; // not working

// Dropdowns
//
$dropdown-min-width:                14rem;
// $dropdown-padding-x:                0 !default;
// $dropdown-padding-y:                .5rem !default;
// $dropdown-spacer:                   .125rem !default;
$dropdown-font-size:                $font-size-md;
// $dropdown-color:                    $body-color !default;
// $dropdown-bg:                       $white !default;
$dropdown-border-color:             transparent;
$dropdown-border-radius:            0;
// $dropdown-border-width:             $border-width !default;
// $dropdown-inner-border-radius:      subtract($dropdown-border-radius, $dropdown-border-width) !default;
// $dropdown-divider-bg:               $dropdown-border-color !default;
// $dropdown-divider-margin-y:         $spacer / 2 !default;
// $dropdown-box-shadow:               $box-shadow !default;

$dropdown-link-color:               $primary;
$dropdown-link-hover-color:         $primary-500-a40;
$dropdown-link-hover-bg:            $white;

$dropdown-link-active-color:        $body-color;
$dropdown-link-active-bg:           $white;

// $dropdown-link-disabled-color:      $gray-500 !default;

// $dropdown-item-padding-y:           $spacer / 4 !default;
// $dropdown-item-padding-x:           $spacer !default;

// $dropdown-header-color:             $gray-600 !default;
// $dropdown-header-padding:           $dropdown-padding-y $dropdown-item-padding-x !default;
