$narrow-max-width: 848px;
$color-transition: color 0.15s ease-in-out;

html {
  scroll-behavior: auto !important;
}

body {
  position: relative;
  font-size: $font-size-sm;
  line-height: $line-height-md;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Change the default tap highlight in iOS
  -webkit-tap-highlight-color: $gray-900-a40;

  @include media-breakpoint-up(sm) {
    font-size: $font-size-md;
    line-height: $line-height-lg;
  }
}

a {
  transition: $color-transition;

  &:hover {
    color: $primary-500-a60;
  }
}

.wrapper {
  max-width: 1440px;
  width: 100%;
  padding: 0 $spacer;
  margin: 0 auto;

  @include media-breakpoint-up(md) {
    padding: 0 ($spacer * 1.5);
  }

  @include media-breakpoint-up(lg) {
    padding: 0 ($spacer * 2.5);
  }
}

h1 {
  font-weight: $font-weight-bolder;
}

h2, h3, h4, h5, h6 {
  font-weight: $font-weight-medium;
}

.narrow {
  max-width: $narrow-max-width;
}

.dropdown-toggle:after {
  display: none;
}
