$component: 'timeline';

$timeline-year-translate-y-lg: 65px;
$timeline-year-translate-y-xs: 48px;
// $timeline-year-translate-y-xs: 28px;

$timeline-left-spacer-xl: $line-left-spacer-xl;
$timeline-left-spacer-lg: $line-left-spacer-lg;
$timeline-left-spacer-md: $line-left-spacer-md;
$timeline-left-spacer-sm: $line-left-spacer-sm;
$timeline-left-spacer-xs: $line-left-spacer-xs;

$timeline-item-pl-sm: ($spacer * 3);
$timeline-item-pl-xs: ($spacer * 2);

.#{$component} {
  @extend .line;
  position: relative;

  &__year {
    margin-left: $timeline-left-spacer-xs;
    padding-left: $timeline-item-pl-xs;

    transform: translateY($timeline-year-translate-y-xs);

    font-size: $h3-font-size;
    line-height: 1;
    font-weight: $font-weight-bolder;
    color: $primary;

    @include media-breakpoint-up(sm) {
      margin-left: $timeline-left-spacer-sm;
      padding-left: $timeline-item-pl-sm;

      // transform: translateY($timeline-year-translate-y-sm);

      font-size: $h1-font-size;
    }

    @include media-breakpoint-up(md) {
      margin-left: $timeline-left-spacer-md;
    }

    @include media-breakpoint-up(lg) {
      margin-left: $timeline-left-spacer-lg;

      transform: translateY($timeline-year-translate-y-lg);
    }

    @include media-breakpoint-up(xl) {
      position: sticky;
      top: 0;
      z-index: 1;

      float: left;
      padding-bottom: ($spacer * 1.5);
      padding-left: 0;
      margin-left: 0;
    }

    & + .#{$component}__item {
      padding-top: $spacer;

      @include media-breakpoint-up(sm) {
        padding-top: ($spacer * 1.5);
      }
    }
  }
}
